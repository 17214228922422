import React, { FC, useState } from 'react';
import LoginForm from 'app/components/Modal/LoginForm';
import { RegisterModalProviderProps } from './models.d';

export const RegisterModalContext = React.createContext<{
  openLoginModal: () => void;
}>({
  openLoginModal: () => ({}),
});

export const RegisterModalProvider: FC<RegisterModalProviderProps> = ({
  children,
}) => {
  const [isLoginOpened, setIsLoginOpened] = useState(false);

  const openLoginModal = () => {
    setIsLoginOpened(true);
  };

  const closeLoginModal = () => {
    setIsLoginOpened(false);
  };

  return (
    <RegisterModalContext.Provider
      value={{
        openLoginModal: openLoginModal,
      }}
    >
      <LoginForm
        onCanelClick={closeLoginModal}
        isModalOpened={isLoginOpened}
        closeLoginModal={closeLoginModal}
      />

      {children}
    </RegisterModalContext.Provider>
  );
};
