import React from 'react';

export namespace TYPOGRAPHY {
  export enum SIZE {
    HUGE = 'huge',
    LARGE = 'large',
    REGULAR = 'regular',
    MEDIUM = 'medium',
    SMALL = 'small',
  }

  export enum COLOR {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    BLACK = 'black',
    WHITE = 'white',
  }

  export enum VARIANT {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
    P = 'p',
  }
}

export interface TypographyProps {
  variant?: TYPOGRAPHY.VARIANT;
  size?: TYPOGRAPHY.SIZE;
  isBold?: boolean;
  lineHeight?: TYPOGRAPHY.LINE_HEIGHT;
  color?: TYPOGRAPHY.COLOR;
  children: React.ReactNode;
  className?: string;
  inlineStyle?: React.CSSProperties;
}
