/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { HomePage } from './pages/Home/Loadable';
// import { DashboardPage } from './pages/Dashboard/Loadable';
import { ArticlePage } from './pages/Article/Loadable';
import { AccountSettingsPage } from './pages/AccountSettings/Loadable';
import { ResetPasswordPage } from './pages/ResetPassword/Loadable';
import { QuantPage } from './pages/Quant/Loadable';
import { BehavioralPage } from './pages/Behavioral/Loadable';
import { GlobalThemesPage } from './pages/GlobalThemes/Loadable';
import { FundamentalPage } from './pages/Fundamental/Loadable';
import { InsightsPage } from './pages/Insights/Loadable';
import { DefaultPortfolioPage } from './pages/DefaultPortfolio/Loadable';
import { TemplatePage } from './pages/Template/Loadable';
import { PortfolioTearsheetPage } from './pages/PortfolioTearsheet/Loadable';
import { EtfgTearsheetPage } from './pages/EtfgTearsheet/Loadable';
import { EquityExposureReportPage } from './pages/EquityExposureReport/Loadable';
import { GlobalScannerPage } from './pages/GlobalScanner/Loadable';
import { PortfolioPage } from './pages/Portfolio/Loadable';
import { SearchPage } from './pages/Search/Loadable';
import { RegistrationPage } from './pages/Registration/Loadable';
import { FAQPage } from './pages/FAQ/Loadable';
import { NotFoundPage } from './pages/NotFound/Loadable';
import { RegisterConfirmationPage } from './pages/RegisterConfirmation/Loadable';
import PrivateRoute from 'app/components/PrivateRoute';
import Loader from 'app/components/Loader';
import { SearchContextProvider } from 'app/contexts/SearchContext';
import {
  WSUserContext,
  WSUserProvider,
} from 'app/contexts/UserContext/UserContext';
import {
  ETFG_TEARSHEET_PAGE,
  EQUITY_EXPOSURE_REPORT_PAGE,
  QUANT_RANKINGS_QUANT,
  QUANT_RANKINGS_BEHAVIORAL,
  QUANT_RANKINGS_GLOBAL_THEMES,
  QUANT_RANKINGS_FUNDAMENTAL,
  ACCOUNT_SETTINGS_PAGE,
  DEFAULT_PORTFOLIO_PAGE,
  INSIGHTS_PAGE,
  PORTFOLIO_TEARSHEET_PAGE,
  SEARCH_PAGE,
  SIGN_UP_PAGE,
  FAQS_PAGE,
  GLOBAL_SCANNER_PAGE,
  NOT_FOUND_PAGE,
  REGISTER_CONFIRMATION_PAGE,
} from 'app/constants/pages';



export function App() {
  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - ETF Global"></Helmet>

      <Switch>
        <SearchContextProvider>
          <WSUserProvider>
            <WSUserContext.Consumer>
              {user => {
                return !user.loading ? (
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    {/* <PrivateRoute
                      exact
                      path="/dashboard"
                      authed={user.isAuthenticated()}
                      component={DashboardPage}
                    /> */}
                    <PrivateRoute
                      exact
                      path={`/${ACCOUNT_SETTINGS_PAGE}`}
                      authed={user.isAuthenticated()}
                      component={AccountSettingsPage}
                    />
                    <PrivateRoute
                      exact
                      path="/portfolio/:id"
                      authed={user.isAuthenticated()}
                      component={PortfolioPage}
                    />
                    <PrivateRoute
                      exact
                      path="/etfg-tearsheet/:ticker"
                      authed={user.isAuthenticated()}
                      component={EtfgTearsheetPage}
                    />
                    <Route
                      exact
                      path="/equity-exposure-report/:query_params+"
                      component={EquityExposureReportPage}
                    />
                    <Route
                      exact
                      path="/reset-password/user/:encoded_pk/:token"
                      component={ResetPasswordPage}
                    />
                    <Route
                      exact
                      path={`/${INSIGHTS_PAGE}`}
                      component={InsightsPage}
                    />
                    <Route
                      exact
                      path={`/${DEFAULT_PORTFOLIO_PAGE}`}
                      component={DefaultPortfolioPage}
                    />
                    <Route
                      exact
                      path="/insights/:slug"
                      component={ArticlePage}
                    />
                    <Route
                      exact
                      path={`/${PORTFOLIO_TEARSHEET_PAGE}`}
                      component={PortfolioTearsheetPage}
                    />
                    <Route
                      exact
                      path={`/${SEARCH_PAGE}`}
                      component={SearchPage}
                    />
                    <Route
                      exact
                      path={`/${SIGN_UP_PAGE}`}
                      component={RegistrationPage}
                    />
                    <Route
                      exact
                      path={`/${REGISTER_CONFIRMATION_PAGE}`}
                      component={RegisterConfirmationPage}
                    />
                    <Route exact path={`/${FAQS_PAGE}`} component={FAQPage} />
                    <Route
                      exact
                      path={`/${ETFG_TEARSHEET_PAGE}`}
                      component={EtfgTearsheetPage}
                    />
                    <Route
                      exact
                      path={`/${EQUITY_EXPOSURE_REPORT_PAGE}`}
                      component={EquityExposureReportPage}
                    />
                    <Route
                      exact
                      path={`/${GLOBAL_SCANNER_PAGE}`}
                      component={GlobalScannerPage}
                    />
                    <Route
                      exact
                      path={`/${QUANT_RANKINGS_QUANT}`}
                      component={QuantPage}
                    />
                    <Route
                      exact
                      path={`/${QUANT_RANKINGS_BEHAVIORAL}`}
                      component={BehavioralPage}
                    />
                    <Route
                      exact
                      path={`/${QUANT_RANKINGS_GLOBAL_THEMES}`}
                      component={GlobalThemesPage}
                    />
                    <Route
                      exact
                      path={`/${QUANT_RANKINGS_FUNDAMENTAL}`}
                      component={FundamentalPage}
                    />
                    <Route
                      exact
                      path={`/${NOT_FOUND_PAGE}`}
                      component={NotFoundPage}
                    />
                    <Route exact path="/:slug" component={TemplatePage} />
                    <Route component={NotFoundPage} />
                  </Switch>
                ) : (
                  <Loader />
                );
              }}
            </WSUserContext.Consumer>
          </WSUserProvider>
        </SearchContextProvider>
      </Switch>
    </BrowserRouter>
  );
}
