import React from 'react';
import Typography from 'app/components/Typography';
import { TYPOGRAPHY } from 'app/components/Typography/models.d';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { HeaderProps } from './models';
import './Header.scss';

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <div className="form-header">
      <BsFillArrowRightCircleFill className="form-header__icon" />
      <Typography
        variant={TYPOGRAPHY.VARIANT.H4}
        color={TYPOGRAPHY.COLOR.WHITE}
        className="form-header__title"
      >
        {title}
      </Typography>
    </div>
  );
};

export default Header;
