import type { AxiosError } from 'axios';
import Axios from 'axios';
import { HTTPStatusCodes } from 'app/utils/httpStatuses';


// Add interface for the error response
interface TokenError {
  code: string;
  detail: string;
  messages: Array<{
    token_class: string;
    token_type: string;
    message: string;
  }>;
}

function isAxiosError(e: Error | AxiosError): e is AxiosError {
  return 'isAxiosError' in e && e.isAxiosError;
}

function isAuthError(e: AxiosError) {
  if (e.code === HTTPStatusCodes.ClientError.Unauthorized) {
    return true;
  }
  if (
    e.response &&
    e.response.status.toString() === HTTPStatusCodes.ClientError.Unauthorized
  ) {
    return true;
  }
  return false;
}

function addRequestInterceptor(config: { token: { current: string } }) {
  const { token } = config;
  return Axios.interceptors.request.use(
    conf => {
      if (token.current) {
        conf.headers!.Authorization = `Bearer ${token.current}`;
      }
      return conf;
    },
    e => e,
  );
}

function addResponseInterceptor(config: {
  refreshToken: { current: string | undefined };
  onRequestError: () => void;
  tokenRefreshMethod: (rt: string) => Promise<boolean>;
  handleSessionExpiration: () => void;
}) {
  const { onRequestError, refreshToken, tokenRefreshMethod, handleSessionExpiration } = config;
  const refreshQueue: Set<string> = new Set();
  
  return Axios.interceptors.response.use(
    success => {
      refreshQueue.delete(success.request.responseURL);
      return success;
    },
    async (error: Error | AxiosError) => {
      if (isAxiosError(error) && isAuthError(error)) {
        const tokenError = error.response?.data as TokenError;
        if (tokenError?.code === 'token_not_valid') {
          if (refreshQueue.has(error.request.responseURL)) {
            handleSessionExpiration();
            refreshQueue.delete(error.request.responseURL);
          } else {
            refreshQueue.add(error.request.responseURL);
            if (refreshToken.current) {
              const success = await tokenRefreshMethod(refreshToken.current);
              if (success) {
                return Axios.request(error.config);
              } else {
                refreshQueue.delete(error.request.responseURL);
                handleSessionExpiration();
              }
            }
          }
        } else {
          onRequestError();
        }
      }
      return Promise.reject(error);
    },
  );
}

export function addAxiosInterceptors(options: {
  token: { current: string };
  refreshToken: { current: string | undefined };
  tokenRefreshMethod: (rt: string) => Promise<boolean>;
  onRequestError: () => void;
  handleSessionExpiration: () => void;
}): [requestInterceptorId: number, responseInterceptorId: number] {
  const { token, onRequestError, refreshToken, tokenRefreshMethod, handleSessionExpiration } = options;

  const requestInterceptor = addRequestInterceptor({ token });
  const responseInterceptor = addResponseInterceptor({
    onRequestError,
    refreshToken,
    tokenRefreshMethod,
    handleSessionExpiration,
  });

  return [requestInterceptor, responseInterceptor];
}