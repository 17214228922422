const ETFG_TEARSHEET_PAGE = 'etfg-tearsheet';
const EQUITY_EXPOSURE_REPORT_PAGE = 'equity-exposure-report';
const QUANT_RANKINGS_QUANT = 'quant-rankings/quant';
const QUANT_RANKINGS_BEHAVIORAL = 'quant-rankings/behavioral';
const QUANT_RANKINGS_GLOBAL_THEMES = 'quant-rankings/global-themes';
const QUANT_RANKINGS_FUNDAMENTAL = 'quant-rankings/fundamental';
const ACCOUNT_SETTINGS_PAGE = 'account-settings';
const DEFAULT_PORTFOLIO_PAGE = 'default-portfolio';
const INSIGHTS_PAGE = 'insights';
const PORTFOLIO_TEARSHEET_PAGE = 'portfolio-tearsheet';
const SEARCH_PAGE = 'search';
const SIGN_UP_PAGE = 'sign-up';
const FAQS_PAGE = 'faqs';
const GLOBAL_SCANNER_PAGE = 'global-scanner';
const NOT_FOUND_PAGE = 'page-not-found';
const REGISTER_CONFIRMATION_PAGE = 'confirmation';

export {
  ETFG_TEARSHEET_PAGE,
  EQUITY_EXPOSURE_REPORT_PAGE,
  QUANT_RANKINGS_QUANT,
  QUANT_RANKINGS_BEHAVIORAL,
  QUANT_RANKINGS_GLOBAL_THEMES,
  QUANT_RANKINGS_FUNDAMENTAL,
  ACCOUNT_SETTINGS_PAGE,
  DEFAULT_PORTFOLIO_PAGE,
  INSIGHTS_PAGE,
  PORTFOLIO_TEARSHEET_PAGE,
  SEARCH_PAGE,
  SIGN_UP_PAGE,
  FAQS_PAGE,
  GLOBAL_SCANNER_PAGE,
  NOT_FOUND_PAGE,
  REGISTER_CONFIRMATION_PAGE
};
