export namespace BUTTON {
  export enum SIZE {
    BIG = 'big',
    MEDIUM = 'medium',
    SMALL = 'small',
  }
  export enum VERSION {
    BUTTON = 'button',
    INTERNAL_LINK = 'internalLink',
    EXTERNAL_LINK = 'externalLink',
  }
  export enum VARIANT {
    TEXT = 'text',
    CONTAINED = 'contained',
    OUTLINE = 'outline',
    PDF = 'pdf',
    FORM = 'form',
    COMPOSITON = 'composition',
  }
  export enum COLOR {
    WHITE = 'white',
    BLUE = 'blue',
    BLUE_DARK = 'blue-dark',
    BLUE_LIGHT = 'blue-light',
    ORANGE = 'orange',
    ORANGE_SECONDARY = 'orange-secondary',
    RED = 'red',
    GRAY = 'gray',
    GRAY_LIGHT = 'gray-light',
    GREEN = 'green',
    VIOLET = 'violet',
    CHATHAMS_BLUE = 'chathams-blue',
  }
  export enum ROUND {
    NONE = 'none',
    LIGHT = 'light',
    MEDIUM_LIGHT = 'medium-light',
    MEDIUM = 'medium',
    STRONG = 'strong',
  }
}

export interface ToogleLinkButtonParams {
  onClick?: () => void;
  className?: string;
  color?: BUTTON.COLOR;
  variant?: BUTTON.VARIANT;
  size?: BUTTON.SIZE;
  round?: BUTTON.ROUND;
  square?: boolean;
  rounded?: boolean;
  link?: string;
  disabled?: boolean;
  PrependChild?: React.ReactNode;
  AppendChild?: React.ReactNode;
  loading?: boolean;
  version?: BUTTON.VERSION;
  type?: 'button' | 'submit';
  children: React.ReactNode;
}
