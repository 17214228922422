import React from 'react';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import classNames from 'classnames';
import { BUTTON, ToogleLinkButtonParams } from './models.d';
import './ToogleLinkButton.scss';

export const ToogleLinkButton: React.FC<ToogleLinkButtonParams> = ({
  onClick,
  className,
  children,
  link,
  loading = false,
  color = BUTTON.COLOR.BLUE,
  variant = BUTTON.VARIANT.CONTAINED,
  size = BUTTON.SIZE.MEDIUM,
  round = BUTTON.ROUND.LIGHT,
  type = 'button',
  version = BUTTON.VERSION.BUTTON,
  square = false,
  disabled = false,
  PrependChild,
  AppendChild,
}) => {
  const buttonClasses = classNames(
    'toogle-button',
    {
      [`toogle-button-${variant}-${color}`]: color,
      [`toogle-button-${variant}`]: variant,
      [`toogle-button-round-${round}`]: round,
      [`toogle-button-${size}`]: size,
      'toogle-button-square': square,
      'toogle-button--disabled': disabled,
    },
    className,
  );

  const buttonContent = (
    <span className="toogle-button__content">
      {PrependChild}
      <span>{!loading ? children : <Spin />}</span>
      {AppendChild}
    </span>
  );

  const returnBtn = () => {
    switch (String(version)) {
      case BUTTON.VERSION.INTERNAL_LINK:
        return (
          <Link to={link} onClick={onClick} className={buttonClasses}>
            {buttonContent}
          </Link>
        );
      case BUTTON.VERSION.EXTERNAL_LINK:
        return (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className={buttonClasses}
          >
            {buttonContent}
          </a>
        );
      case BUTTON.VERSION.BUTTON:
        return (
          <button onClick={onClick} type={type} className={buttonClasses}>
            {buttonContent}
          </button>
        );
      default:
        return null;
    }
  };

  return returnBtn();
};
