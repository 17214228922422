import { useContext } from 'react';
import { RegisterModalContext } from 'app/contexts/RegisterModalContext/RegisterModalContext';
import { message } from 'antd';

const useAuthInterceptor = () => {
  const { openLoginModal } = useContext(RegisterModalContext);

  const handleSessionExpiration = () => {
    message.error('Your session has expired. Please sign in again.');
    openLoginModal();
  };

  return {
    handleSessionExpiration
  };
};

export default useAuthInterceptor;