import React, { useRef } from 'react';
import { Input, Form, message } from 'antd';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FormInstance } from 'antd/lib/form';
import { ToogleLinkButton } from 'app/components/ToogleLinkButton/ToogleLinkButton';
import { BUTTON } from 'app/components/ToogleLinkButton/models.d';
import Typography from 'app/components/Typography';
import Header from '../Header';
import { recoverPassword } from 'app/adapters/Auth';
import { TYPOGRAPHY } from 'app/components/Typography/models.d';
import { RecoverPasswordFormProps } from './models';

const RecoverPasswordForm: React.FC<RecoverPasswordFormProps> = ({
  setIsLoginForm,
}) => {
  const [formReset] = Form.useForm();
  const formRefReset = useRef<FormInstance>(null);

  const onFormResetSubmit = (values: CommonTypes.FormValuesType) => {
    recoverPassword(values['email'].toString())
      .then(response => {
        if (response.status === 200) {
          message.success('The mail was sent successfully');
          formRefReset.current && formReset.resetFields();
        }
      })
      .catch(e => {
        if (e.response.status === 400) {
          message.error(`User doesn't exists`);
        }
      });
  };
  return (
    <>
      <Header title="Forgot Password?" />
      <Typography
        variant={TYPOGRAPHY.VARIANT.P}
        color={TYPOGRAPHY.COLOR.WHITE}
        className="form-modal__footer"
      >
        If you have forgotten your password, enter your
        <strong> email address</strong> below to generate a new.
      </Typography>
      <Form
        ref={formRefReset}
        form={formReset}
        onFinish={onFormResetSubmit}
        name="form-reset"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'The input is not valid E-mail',
            },
          ]}
        >
          <Input
            style={{ height: '38px' }}
            placeholder="Email"
            autoComplete="off"
          />
        </Form.Item>
        <span className={'form__had-account'}>
          <ToogleLinkButton
            onClick={() => setIsLoginForm(true)}
            variant={BUTTON.VARIANT.TEXT}
          >
            Back to Login <AiOutlineArrowLeft />
          </ToogleLinkButton>
        </span>
        <Form.Item>
          <ToogleLinkButton type="submit" variant={BUTTON.VARIANT.FORM}>
            GO
          </ToogleLinkButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default RecoverPasswordForm;
