import Axios from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useAuthInterceptor from 'app/hooks/useAuthInterceptor';

import {
  amperLogIn,
  amperLogOut,
  amperChangePassword,
  amperRefreshToken,
  amperChangeUserData,
  amperRegister,
} from 'app/utils/amperAuth';
import { addAxiosInterceptors } from 'app/utils/axiosInterceptors';
import {
  getLocalRefreshToken,
  setLocalRefershToken,
} from 'app/utils/refreshToken';
import { useTrackedRef } from 'app/utils/trackedRef';

export const useAmperAuth = (onAuthChange: (isLoggedIn: boolean) => void) => {
  const [isReady, setIsReady] = React.useState(false);
  const token = React.useRef('');
  const refreshToken = useTrackedRef<undefined | string>(undefined);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

   // Add this line to get the session expiration handler
  const { handleSessionExpiration } = useAuthInterceptor();

  const register = amperRegister;

  const changeUserData = amperChangeUserData;

  const changePassword = amperChangePassword;

  const login = (username: string, password: string) => {
    return amperLogIn(username, password).then(({ data, error }) => {
      if (error) {
        return error;
      }
      token.current = data.access;
      refreshToken.current = data.refresh;
      changeAuth();
      if (data.access) {
        return undefined;
      }
    });
  };

  const changeAuth = () => {
    if (refreshToken.current && refreshToken.current.length > 0) {
      onAuthChange(true);
    } else {
      onAuthChange(false);
    }
  };

  const logout = () => {
    if (refreshToken.current) {
      amperLogOut(refreshToken.current).finally(() => {
        token.current = '';
      });
      refreshToken.current = '';
    }
    changeAuth();
    history.push('/');
  };

  const tryTokenRefresh = async (refresh_token: string) => {
    return amperRefreshToken(refresh_token).then(data => {
      token.current = data.access;
      refreshToken.current = data.refresh;
      if (data.access) return true;
      history.push('/');
      setLoading(false);
      return false;
    });
  };

  React.useEffect(() => {
    // On component load, retrieve refresh token from local storage,
    // if a refresh token exists use it to fetch access token
    const retoken = getLocalRefreshToken();
    if (retoken) {
      setLoading(true);
      refreshToken.current = retoken;
      tryTokenRefresh(retoken).finally(() => {
        changeAuth();
      });
    }

    const refTokenListener = refreshToken.addListener(reftoken => {
      if (reftoken !== undefined) {
        // whenever the refresh token changes, save it to the local storage
        setLocalRefershToken(reftoken);
      }
    });

    // add interceptors for handling token refreshes and adding tokens to the requests
    const [requestInterceptor, responseInterceptor] = addAxiosInterceptors({
      token,
      refreshToken,
      onRequestError: logout,
      tokenRefreshMethod: tryTokenRefresh,
       handleSessionExpiration,
    });
    setIsReady(true);

    return () => {
      Axios.interceptors.request.eject(requestInterceptor);
      Axios.interceptors.response.eject(responseInterceptor);
      refreshToken.removeListener(refTokenListener);
    };
  }, [handleSessionExpiration]);

  return {
    login,
    logout,
    changePassword,
    changeUserData,
    isReady,
    loading,
    setLoading,
    register,
  };
};
