import Axios from 'axios';
import { urls } from '../auth-urls';

export const recoverPassword = (email: string) =>
  Axios.post(urls.recoverPassword, {
    email: email,
  });

export const resetPassword = (
  encoded_pk: string,
  token: string,
  password: string,
  repeat_password: string,
) =>
  Axios.patch(`${urls.resetPassword}${encoded_pk}/${token}/`, {
    password,
    repeat_password,
  });
