import React, { useContext, useRef, useEffect } from 'react';
import Axios from 'axios';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Drawer, Input, Form, message, Alert } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { LoginFormProps } from './models.d';
import { ToogleLinkButton } from 'app/components/ToogleLinkButton/ToogleLinkButton';
import { BUTTON } from 'app/components/ToogleLinkButton/models.d';
import Header from '../Header';
import RecoverPasswordForm from './RecoverPasswordForm';
import { WSUserContext } from 'app/contexts/UserContext/UserContext';
import '../Form.scss';
import './LoginForm.scss';

const LoginForm: React.FC<LoginFormProps> = ({
  onCanelClick,
  isModalOpened,
  closeLoginModal,
}) => {
  const user = useContext(WSUserContext);
  const [isLoginForm, setIsLoginForm] = React.useState(true);
  const [showSessionExpired, setShowSessionExpired] = React.useState(false);

  const [formLogin] = Form.useForm();
  const formRefLogin = useRef<FormInstance>(null);

  // Add effect to handle session expiration
  useEffect(() => {
    if (isModalOpened) {
      const isSessionExpired = localStorage.getItem('sessionExpired');
      if (isSessionExpired) {
        setShowSessionExpired(true);
        localStorage.removeItem('sessionExpired');
      }
    }
  }, [isModalOpened]);

  const onFormLoginSubmit = (values: CommonTypes.FormValuesType) => {
    setShowSessionExpired(false); 
    user
      .login?.(values['username'].toString(), values['password'].toString())
      .then(isError => {
        if (
          isError?.detail === 'Incorrect password!' ||
          isError?.detail === 'Not found.'
        ) {
          message.error('Incorrect password or username!');
        }
      })
      .catch(e => {
        if (!Axios.isCancel(e)) {
          message.error('An error occurred during login');
        }
      });
  };

  return (
    <Drawer
      className="form-drawer form"
      placement={'right'}
      closable={true}
      onClose={onCanelClick}
      visible={isModalOpened}
      key={'right'}
      maskStyle={{
        backgroundColor: 'rgba(11, 31, 58,.85)',
      }}
    >
      {isLoginForm ? (
        <>
          <div className="form-drawer__login">
            <Header title="Login to your account" />
            {showSessionExpired && (
              <Alert
                message="Session Expired"
                description="Your session has expired. Please sign in again to continue."
                type="warning"
                showIcon
                className="session-expired-alert"
                style={{ marginBottom: '20px' }}
              />
            )}
            <Form
              ref={formRefLogin}
              form={formLogin}
              onFinish={onFormLoginSubmit}
              layout="vertical"
              name="form"
              size="middle"
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Enter Username' }]}
              >
                <Input
                  style={{ height: '38px' }}
                  placeholder="Username"
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Enter Password' }]}
              >
                <Input.Password
                  style={{ height: '38px' }}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <span className={'form__had-account'}>
                <ToogleLinkButton
                  onClick={() => setIsLoginForm(false)}
                  variant={BUTTON.VARIANT.TEXT}
                >
                  Forgot Password? <AiOutlineArrowRight />
                </ToogleLinkButton>
              </span>
              <Form.Item>
                <ToogleLinkButton type="submit" variant={BUTTON.VARIANT.FORM}>
                  GO
                </ToogleLinkButton>
              </Form.Item>
            </Form>
          </div>
          <div className="form-drawer__sign-up">
            <Header title="Sign up for your free account" />
            <ToogleLinkButton
              version={BUTTON.VERSION.INTERNAL_LINK}
              variant={BUTTON.VARIANT.FORM}
              link={'/sign-up'}
              onClick={closeLoginModal}
            >
              SIGN UP
            </ToogleLinkButton>
          </div>
        </>
      ) : (
        <RecoverPasswordForm setIsLoginForm={setIsLoginForm} />
      )}
    </Drawer>
  );
};

export default LoginForm;
