import React, { FC } from 'react';
import { TypographyProps, TYPOGRAPHY } from './models.d';
import classNames from 'classnames';
import './Typography.scss';

const Typography: FC<TypographyProps> = ({
  variant = TYPOGRAPHY.VARIANT.H1,
  size = TYPOGRAPHY.SIZE.REGULAR,
  color = TYPOGRAPHY.COLOR.PRIMARY,
  isBold = false,
  children,
  className,
  inlineStyle,
}) => {
  const TypographyClasses = classNames(
    {
      [`typography-${variant}`]: variant,
      [`typography-${variant}--${size}`]: size,
      [`typography--${color}`]: color,
      [`typography--bold`]: isBold,
    },
    className,
  );

  const Component = variant;
  return (
    <Component className={TypographyClasses} style={inlineStyle}>
      {children}
    </Component>
  );
};

export default Typography;
